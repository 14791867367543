import * as React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import Carousel from '../components/Carousel'
import Columns from '../components/Columns'
import Cta from '../components/Cta'
import Stack from '@mui/material/Stack'
import Text from '../components/Text'
import Testimonials from '../components/Testimonials'
import Toolbar from '@mui/material/Toolbar'
import TrustedBy from '../components/TrustedBy'

// Content
import { contentGeneralPage } from '../content/generalPage'
import { logoSlideContent } from '../content/logoSlide'

const AboutUsPage = () => {
  const { t } = useTranslation()
  return (
    <Layout headerWhite={true}>
      <Toolbar />
      <Columns content={contentGeneralPage(t).columnsCover} />
      <Columns content={contentGeneralPage(t).columns1} />
      <Columns content={contentGeneralPage(t).columns2} />
      <Columns content={contentGeneralPage(t).columns3} />
      <Columns content={contentGeneralPage(t).columns4} />
      <Columns content={contentGeneralPage(t).columns5} />
      <Columns content={contentGeneralPage(t).columns6} />
      <Text content={contentGeneralPage(t).text} />
      <Stack direction='column' alignItems='center' spacing={2}>
        <TrustedBy mode={'dark'} disposition={'row'} text={t('BUSINESSES')} show={false} />
        <Carousel className='mt-s-28' content={logoSlideContent('big', 'default', true).carousel} />
      </Stack>
      <Testimonials />
      <Cta content={contentGeneralPage(t).cta} />
    </Layout>
  )
}

export const Head = ({ location }) => (
  <Seo
    title='Find a new supplier'
    description='Look no further. Our global B2B market network is the smarter, safer, transparent, and more sustainable solution you´ve been waiting for to discover new suppliers of apparel, fabric, trims, yarns and home textiles. Streamline your network management, navigate from discovering sellers to placing orders and secure payments.'
    pathname={location.pathname}
  />
)

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default AboutUsPage
